import React, {Component} from 'react';
import {
    saveTerms,
    toggleIsError,
    toggleIsLoading
} from '../MyCourses/Terms/actions';
import {connect} from 'react-redux';
import {loadTerms} from '../common/terms';

class Essentials extends Component {
    componentDidMount() {
        this.loadEssentials();
    }

    // Loads all the essentials
    loadEssentials = () => {
        loadTerms({
            termsCreatedAt: this.props.termsCreatedAt,
            termsData: this.props.terms,
            toggleIsLoading: this.props.toggleIsLoading,
            toggleIsError: this.props.toggleIsError,
            saveTerms: this.props.saveTerms
        });
    };

    render() {
        return <>{this.props.children}</>;
    }
}

const mapStateToProps = state => {
    return {
        termsCreatedAt: state.termsReducer.createdAt,
        terms: state.termsReducer.terms
    };
};

const mapDispatchToProps = dispatch => ({
    saveTerms: terms => dispatch(saveTerms(terms)),
    toggleIsLoading: isLoading => dispatch(toggleIsLoading(isLoading)),
    toggleIsError: isError => dispatch(toggleIsError(isError))
});

export default connect(mapStateToProps, mapDispatchToProps)(Essentials);
