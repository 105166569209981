import {getData} from '../../DataAccessLayer';
import {terms} from '../../DataAccessLayer/services';
import {Time} from './MomentTime';

// Θ(1) Loads the previous, current and next term details
// TODO: Revisit when the util function can access the store directly
export const loadTerms = async ({
    termsCreatedAt,
    termsData,
    toggleIsLoading,
    toggleIsError,
    saveTerms
}) => {
    try {
        if (
            !termsCreatedAt ||
            Time.University(termsCreatedAt).format('YYYY-MM-DD') !==
                Time.University().format('YYYY-MM-DD') ||
            !termsData['CURRENT_TERM']
        ) {
            toggleIsLoading(true);
            toggleIsError(false);
            const termsResponse = await getData(terms, true);
            saveTerms(termsResponse);
        }
    } catch (error) {
        console.log('Error loading terms', error);
        toggleIsError(true);
    }
    toggleIsLoading(false);
};
