import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Grid, Skeleton} from '@mui/material';
import {connect} from 'react-redux';
import BalanceBox from './BalanceBox';
import {cardCenterMonarch, studentAccount} from '../common/constants';
import {myBalances} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import ApiErrorMessage from '../common/ApiErrorMessage';
import {
    saveTerms,
    toggleIsError,
    toggleIsLoading
} from '../MyCourses/Terms/actions';
import {loadTerms} from '../common/terms';

class MyBalances extends Component {
    state = {
        isExpand: true,
        widgetId: 'widget__myBalances',
        isDisplayLaundryBalance: false,
        isDisplayMonarchBalance: false,
        isDisplayTuitionBalance: false,
        myBalancesData: {},
        currentTerm: '',
        altAttribute: '',
        isLoading: false,
        isError: false
    };

    toggleIsDisplayLaundryBalance = () => {
        this.setState({
            isDisplayLaundryBalance: !this.state.isDisplayLaundryBalance
        });
    };

    toggleIsDisplayMonarchBalance = () => {
        this.setState({
            isDisplayMonarchBalance: !this.state.isDisplayMonarchBalance
        });
    };

    toggleIsDisplayTuitionBalance = () => {
        this.setState({
            isDisplayTuitionBalance: !this.state.isDisplayTuitionBalance
        });
    };

    toggleLoading = () => {
        this.setState({isLoading: !this.state.isLoading});
    };

    toggleError = isError => {
        this.setState({isError});
    };

    titleCase = str => {
        return str
            .toLowerCase()
            .split(' ')
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    };

    componentDidMount = () => {
        this.loadBalances();
    };

    loadBalances = () => {
        this.toggleLoading();
        this.toggleError(false);
        getData(myBalances, true)
            .then(myBalancesData => {
                this.setState({
                    myBalancesData
                });
                this.updateCurrentState();
            })
            .catch(err => this.toggleError(true))
            .finally(() => this.toggleLoading());
    };

    componentDidUpdate(prevProps) {
        if (prevProps.terms != this.props.terms) {
            this.updateCurrentState();
        }
    }

    updateCurrentState = () => {
        if (this.props.terms['CURRENT_TERM']) {
            const currentTerm = this.props.terms['CURRENT_TERM'];
            this.setState({
                currentTerm: ' - ' + currentTerm.title,
                altAttribute: currentTerm.title + ' Balance Due'
            });
        }
    };

    render() {
        return (
            <div id={'myBalances__div_balanceMainCard'}>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        isExpand: this.props.widget.isExpand,
                        isRequired: this.props.widget.isRequired
                    }}
                    hasScrollX={true}
                    className="myOdu__myBalances"
                    {...this.props}
                >
                    {(this.state.isLoading || this.props.isTermsLoading) && (
                        <Skeleton variant="rectangular" height={98} />
                    )}
                    {!this.state.isLoading &&
                        !this.props.isTermsLoading &&
                        (this.state.isError || this.props.isTermsError) && (
                            <ApiErrorMessage
                                id={'myBalances__apiErrorMessage'}
                                widgetName={this.props.title}
                                reload={
                                    this.state.isError
                                        ? this.loadBalances
                                        : () =>
                                              loadTerms({
                                                  termsCreatedAt:
                                                      this.props.termsCreatedAt,
                                                  termsData: this.props.terms,
                                                  toggleIsLoading:
                                                      this.props
                                                          .toggleIsLoading,
                                                  toggleIsError:
                                                      this.props.toggleIsError,
                                                  saveTerms:
                                                      this.props.saveTerms
                                              })
                                }
                            />
                        )}
                    {!this.state.isLoading &&
                        !this.props.isTermsLoading &&
                        !this.state.isError &&
                        !this.props.isTermsError && (
                            <div
                                id={this.props.widget.id + '__wrapper'}
                                className="scrollWrapper"
                            >
                                <Grid
                                    id={this.props.widget.id + '__grid_balance'}
                                    sx={{width: '100%'}}
                                    container
                                    className=""
                                    wrap="nowrap"
                                    direction="row"
                                    rowSpacing={2}
                                >
                                    <Grid
                                        id={
                                            'myBalances__gridItem_tuitionBalance'
                                        }
                                        item
                                        className="balanceGridItem"
                                    >
                                        <BalanceBox
                                            id="myBalances__tuitionBalance"
                                            isDisplayBalance={
                                                this.state
                                                    .isDisplayTuitionBalance
                                            }
                                            toggleIsDisplayBalance={
                                                this
                                                    .toggleIsDisplayTuitionBalance
                                            }
                                            depositLink={studentAccount}
                                            buttonLabel="Make Payment"
                                            balance={
                                                this.state.myBalancesData.balanceDue?.toFixed(
                                                    2
                                                ) ?? 'N/A'
                                            }
                                            icon="/img/logos/tuitionBalance.svg"
                                            alt={this.state.altAttribute}
                                            title={this.titleCase(
                                                `Tuition${this.state.currentTerm}`
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        id={
                                            'myBalances__gridItem_laundryBalance'
                                        }
                                        item
                                        className="balanceGridItem"
                                    >
                                        <BalanceBox
                                            id="myBalances__laundryBalance"
                                            isDisplayBalance={
                                                this.state
                                                    .isDisplayLaundryBalance
                                            }
                                            toggleIsDisplayBalance={
                                                this
                                                    .toggleIsDisplayLaundryBalance
                                            }
                                            depositLink={cardCenterMonarch}
                                            buttonLabel="Make Deposit"
                                            balance={
                                                this.state.myBalancesData.laundry?.toFixed(
                                                    2
                                                ) ?? 'N/A'
                                            }
                                            icon="/img/logos/laundry.svg"
                                            alt="Laundry"
                                            title="Laundry"
                                        />
                                    </Grid>
                                    <Grid
                                        id={
                                            'myBalances__gridItem_monarchBalance'
                                        }
                                        item
                                        className="balanceGridItem"
                                    >
                                        <BalanceBox
                                            id="myBalances__monarchBalance"
                                            isDisplayBalance={
                                                this.state
                                                    .isDisplayMonarchBalance
                                            }
                                            toggleIsDisplayBalance={
                                                this
                                                    .toggleIsDisplayMonarchBalance
                                            }
                                            depositLink={cardCenterMonarch}
                                            buttonLabel="Make Deposit"
                                            balance={
                                                this.state.myBalancesData.monarchPlus?.toFixed(
                                                    2
                                                ) ?? 'N/A'
                                            }
                                            icon="/img/monarchCard.svg"
                                            alt="Monarch Card"
                                            title="Monarch Plus"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                </Widget>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        terms: state.termsReducer.terms,
        isTermsLoading: state.termsReducer.isLoading,
        isTermsError: state.termsReducer.isError,
        termsCreatedAt: state.termsReducer.createdAt
    };
};

const mapDispatchToProps = dispatch => ({
    saveTerms: terms => dispatch(saveTerms(terms)),
    toggleIsLoading: isLoading => dispatch(toggleIsLoading(isLoading)),
    toggleIsError: isError => dispatch(toggleIsError(isError))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyBalances);
