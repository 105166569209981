import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Divider, Skeleton, Stack, Typography} from '@mui/material';
import {connect} from 'react-redux';
import {getData} from '../../DataAccessLayer';
import {myProgram, myTransferHistory} from '../../DataAccessLayer/services';
import Credits from './Boxes/Credits';
import GpaAcademicStanding from './Boxes/GpaAcademicStanding';
import ProgramResources from './Boxes/ProgramResources';
import myProgramLinks from './config';
import Major from './Boxes/Major';
import ApiErrorMessage from '../common/ApiErrorMessage';
import {
    saveTerms,
    toggleIsError,
    toggleIsLoading
} from '../MyCourses/Terms/actions';
import {loadTerms} from '../common/terms';

class MyPrograms extends Component {
    state = {
        totalTransferredCredits: 0,
        isDisplayGPA: false,
        nextSem: {},
        programDetails: {},
        nextTerm: '',
        isLoading: false,
        error: {}
    };

    toggleLoading = () => {
        this.setState({isLoading: !this.state.isLoading});
    };

    toggleError = error => {
        this.setState({error: error ? {...this.state.error, ...error} : {}});
    };

    componentDidMount() {
        this.loadMyPrograms([
            this.loadTransferHistory(),
            this.loadProgramDetails()
        ]);
        if (!this.props.isTermsLoading && !this.props.isTermsError) {
            this.setNextTerm();
        }
    }

    loadMyPrograms = services => {
        this.toggleLoading();
        this.toggleError();
        Promise.all(services).finally(() => this.toggleLoading());
    };

    loadTransferHistory = () => {
        return getData(myTransferHistory, true)
            .then(result => {
                this.loadTransferCredits(result[0]?.PREVIOUS_INSTITUTES);
            })
            .catch(err => {
                console.log(err);
                this.toggleError({transferHistory: true});
            });
    };

    loadProgramDetails = () => {
        return getData(myProgram, true)
            .then(result => {
                this.setState({
                    programDetails: result[0]
                });
            })
            .catch(err => {
                console.log(err);
                this.toggleError({programDetails: true});
            });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.coursesTaking !== this.props.coursesTaking) {
            this.getSemesters();
        }

        if (prevProps.terms != this.props.terms) {
            this.setNextTerm();
        }
    }

    //O(N) where N is length of string previousInstitues
    //Retrieves transfer credits from previousInstitues and sums it up
    loadTransferCredits(previousInstitues) {
        if (!previousInstitues) {
            return;
        }
        let totalTransferredCredits = previousInstitues
            .split('|')
            .reduce(
                (total, institute) =>
                    total +
                    Number(
                        institute
                            .substring(
                                institute.lastIndexOf('(') + 1,
                                institute.lastIndexOf(')')
                            )
                            .trim()
                    ),
                0
            );
        this.setState({
            totalTransferredCredits
        });
    }

    toggleIsDisplayGPA = () => {
        this.setState({
            isDisplayGPA: !this.state.isDisplayGPA
        });
    };

    //O(N) where N is length of coursesTaking
    //Retrieves current and next semesters from coursesTaking
    getSemesters = () => {
        let {coursesTaking, terms} = this.props;

        if (coursesTaking?.length) {
            let nextSem =
                coursesTaking?.find(
                    el => el.TERM_CODE === terms.NEXT_TERM?.code
                ) || {};
            this.setState({
                nextSem
            });
        }
    };

    //θ(N) where N is the number of terms (usually 3)
    //Sets the title of next term
    setNextTerm = () => {
        const nextTerm = this.props.terms['NEXT_TERM'];
        this.setState({
            nextTerm: nextTerm?.title || ''
        });
    };

    reload = () => {
        // If the error is due to the failure of terms API, then make a call to fetch the terms
        if (this.props.isTermsError) {
            loadTerms({
                termsCreatedAt: this.props.termsCreatedAt,
                termsData: this.props.terms,
                toggleIsLoading: this.props.toggleIsLoading,
                toggleIsError: this.props.toggleIsError,
                saveTerms: this.props.saveTerms
            });
        }

        // If the error is due to the failure of both transfer history and program details APIs, then make calls to fetch transfer history and program details
        if (Object.keys(this.state.error).length === 2) {
            this.loadMyPrograms([
                this.loadProgramDetails(),
                this.loadTransferHistory()
            ]);
        } else if (this.state.error.transferHistory) {
            // If the error is due to the failure of both transfer history API, then make a call to fetch transfer history
            this.loadMyPrograms([this.loadTransferHistory()]);
        } else if (this.state.error.programDetails) {
            // If the error is due to the failure of both program details API, then make a call to fetch program details
            this.loadMyPrograms([this.loadProgramDetails()]);
        }
    };

    render() {
        const errorKeysLength = Object.keys(this.state.error).length;
        //TODO: Add Updated time
        return (
            <Widget
                data={{
                    id: this.props.widget.id,
                    title: this.props.title,
                    isTitleStylized: this.props.isTitleStylized,
                    isExpand: this.props.widget.isExpand,
                    isRequired: this.props.widget.isRequired
                }}
                className="myOdu__myPrograms"
                hasScrollX={true}
                {...this.props}
            >
                {(this.state.isLoading || this.props.isTermsLoading) && (
                    <Skeleton variant="rectangular" height={98} />
                )}
                {!this.state.isLoading &&
                    !this.props.isTermsLoading &&
                    (errorKeysLength || this.props.isTermsError) && (
                        <ApiErrorMessage
                            id={'myPrograms__apiErrorMessage'}
                            widgetName={this.props.title}
                            reload={this.reload}
                        />
                    )}
                {!this.state.isLoading &&
                    !this.props.isTermsLoading &&
                    !this.props.isTermsError &&
                    !errorKeysLength && (
                        <div
                            id={this.props.widget.id + '__div_wrapper'}
                            className="scrollWrapper"
                        >
                            <Stack
                                id={this.props.widget.id + '__stack'}
                                className=""
                                wrap="nowrap"
                                direction="row"
                                rowSpacing={2}
                                alignItems={'stretch'}
                                alignContent={'stretch'}
                            >
                                <Stack
                                    id={
                                        this.props.widget.id +
                                        '__stack_currentProgram'
                                    }
                                    alignContent={'stretch'}
                                    alignItems={'stretch'}
                                    sx={{height: '100%'}}
                                    className="currentProgram"
                                >
                                    <Typography component="h3">
                                        Current Program
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        className="myOdu__box border currentProgramBox"
                                        divider={
                                            <Divider
                                                id={'myPrograms__line_divider'}
                                                orientation="vertical"
                                                flexItem
                                                sx={{borderColor: 'black'}}
                                                role="presentation"
                                            />
                                        }
                                    >
                                        <Major
                                            id=""
                                            programDetails={
                                                this.state.programDetails
                                            }
                                        />

                                        <GpaAcademicStanding
                                            id=" "
                                            isDisplayGPA={
                                                this.state.isDisplayGPA
                                            }
                                            toggleIsDisplayGPA={
                                                this.toggleIsDisplayGPA
                                            }
                                            programDetails={
                                                this.state.programDetails
                                            }
                                        />

                                        <Credits
                                            id=" "
                                            totalTransferredCredits={
                                                this.state
                                                    .totalTransferredCredits
                                            }
                                            nextSem={this.state.nextSem}
                                            programDetails={
                                                this.state.programDetails
                                            }
                                            nextTerm={this.state.nextTerm}
                                        />
                                    </Stack>
                                </Stack>

                                <Stack
                                    alignContent={'stretch'}
                                    alignItems={'stretch'}
                                    sx={{ml: 1, height: '100%'}}
                                >
                                    <Typography component="h3" variant="h6">
                                        Program Resources
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        className="myOdu__box border programResourcesBox"
                                        sx={{
                                            width: '100%',
                                            p: 1,
                                            m: 0,
                                            height: '100%'
                                        }}
                                        divider={
                                            <Divider
                                                id={'myPrograms__line_divider'}
                                                orientation="vertical"
                                                flexItem
                                                sx={{borderColor: 'black'}}
                                                role="presentation"
                                            />
                                        }
                                    >
                                        <ProgramResources
                                            myProgramLinks={myProgramLinks}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </div>
                    )}
            </Widget>
        );
    }
}

const mapStateToProps = state => {
    return {
        coursesTaking: state.myCoursesReducer.coursesTaking,
        terms: state.termsReducer.terms,
        isTermsLoading: state.termsReducer.isLoading,
        isTermsError: state.termsReducer.isError,
        termsCreatedAt: state.termsReducer.createdAt
    };
};

const mapDispatchToProps = dispatch => ({
    saveTerms: terms => dispatch(saveTerms(terms)),
    toggleIsLoading: isLoading => dispatch(toggleIsLoading(isLoading)),
    toggleIsError: isError => dispatch(toggleIsError(isError))
});

export default connect(mapStateToProps, mapDispatchToProps)(MyPrograms);
